import React from "react";

import useUserConfig from "../../Hooks/useUserConfig";
import UserNavBar from "../userNavbar/UserNavBar";
import { Footer } from "../../../Components/footer/footer";
import Loading from "../../../Components/loading/Loading"
import CharacterAnimateAudio from "../CharacterAnimateAudio";

import cssModule from "./userPageTemplate.module.css";
import * as utils from '../../../Util/utils';


import { S3_PUBLIC } from "../../../config";

const UserPageTemplate = ({
  pageContent,
  footer = null,
  footerClass = null,
  bgClass = "form_page_main_div",
  centerDivCol = "col-12",
  mainDivClass = "defaultMainDiv",
  chars = true
}) => {
  const pageName = utils.getPageNameFromURL(window.location.pathname);

  const { data: userConfig, isLoading } = useUserConfig();

  let graphicConfig = {};
  let sponsorConfig = {};
  let character_0 = {};
  let character_1 = {};

  if (!isLoading) {

    character_0 = null;
    character_1 = null;

    graphicConfig = userConfig.graphicConfig;
    if (footer) {
      sponsorConfig = userConfig.sponsorConfig;
    }

    graphicConfig.characters.forEach(character => {
      if ((character.pages.includes(pageName) || character.pages.includes("all"))) {
        if (character.position === 0 && !character_0) {
          character_0 = character;
        } else if (character.position === 1 && !character_1) {
          character_1 = character;
        }
      }
    });

  }

  return (
    <div className={cssModule.container} style={{ backgroundImage: isLoading ? 'none' : `url(${S3_PUBLIC}/images/${graphicConfig.mainBkg})` }}>
      <nav className={cssModule.navbar}>
        <UserNavBar />
      </nav>
      <Loading isLoading={isLoading} />
      {!isLoading &&
        <main className={cssModule.content}>
          <div className={cssModule[mainDivClass]}>

            {/* Character 1 */}
            {chars &&
              <div className="right_boy hey character1_play col-lg-2 col-xl-2">
                <CharacterAnimateAudio
                  imgSrc={`${S3_PUBLIC}/images/${character_0.image}`}
                  gifSrc={character_0.gif !== "" ? `${S3_PUBLIC}/images/${character_0.gif}` : ""}
                  audioSrc={character_0.audio !== "" ? `${S3_PUBLIC}/images/${character_0.audio}` : ""}
                  className={`img-fluid ${cssModule.charImg}`}
                  style={{}}
                />
              </div>}

            {/* {mainContent} */}
            <div
              className={`col-lg-8 col-xl-6 ${centerDivCol} text-center ${cssModule.centerMainDiv}`}
            >
              <div className={cssModule.centerWrapper} >{pageContent}</div>
            </div>

            {/* Character 2 */}
            {chars &&
              <div className="left_girl hey character2_play col-lg-2 col-xl-2">
                <CharacterAnimateAudio
                  imgSrc={`${S3_PUBLIC}/images/${character_1.image}`}
                  gifSrc={character_1.gif !== "" ? `${S3_PUBLIC}/images/${character_1.gif}` : ""}
                  audioSrc={character_1.audio !== "" ? `${S3_PUBLIC}/images/${character_1.audio}` : ""}
                  className={`img-fluid ${cssModule.charImg}`}
                  style={{}}
                />
              </div>}
          </div>
        </main>}
      {!isLoading && footer && <footer className={cssModule.footer}>
        <Footer footerClass={footerClass} />
      </footer>}

    </div>
  );
};

export default UserPageTemplate;
